import React, { ReactChildren, ReactElement } from 'react';
import { Link } from 'gatsby';

// Components
import {
  Footer,
  Header,
  PostCard,
  WithScrollToTopButton,
  Wrapper,
} from 'components';
import { SEOContent } from 'layouts';

// Hooks
import {
  useAllArticlesImage,
  useHomeHero,
  useHomePageQuery,
  useLogo,
  usePostsWithAdvertisements,
} from 'hooks';

// Styles
import {
  HomePosts,
  inner,
  outer,
  PostFeed,
  PostFeedRaise,
  SiteMain,
} from 'styles';

// Types
import { Post } from 'components/PostCard/types';
import {
  CardStyles,
  LargeCard,
  Title,
} from '../components/SeasonCards/local-styles';
import { FluidImage } from '../components/PostCard/FluidImage';
import {
  PostCardExcerpt,
  PostCardMeta,
} from '../components/PostCard/local-styles';

interface Props {
  children: ReactChildren;
}
export interface Posts {
  data: Post;
  fields: {
    postId: number;
  };
}

const IndexPage = (props: Props): ReactElement => {
  const { children } = props;
  const posts = useHomePageQuery();
  const hero = useHomeHero();
  const logo = useLogo();
  const allArticlesImage = useAllArticlesImage();

  const { postsWithAdvertisements } = usePostsWithAdvertisements(posts);

  return (
    <SEOContent css={HomePosts}>
      <Wrapper>
        <Header logo={logo} hero={hero} />
        <main css={[SiteMain, outer]}>
          <WithScrollToTopButton>
            <div css={inner}>
              <div css={[PostFeed, PostFeedRaise]}>
                {postsWithAdvertisements.map(
                  ({ data, fields }: Posts): ReactElement => (
                    <PostCard
                      isAdvertisement={!!data.advertisements}
                      key={data.post_id}
                      post={data}
                      postId={fields.postId}
                    />
                  )
                )}
                <Link css={CardStyles} to="/posts">
                  <LargeCard>
                    <FluidImage
                      alt="All MSTCA Articles"
                      fluid={allArticlesImage}
                    />
                    <Title>All Articles</Title>
                    <PostCardMeta isCenter>
                      <PostCardExcerpt>
                        <p>Explore older articles on MSTCA</p>
                      </PostCardExcerpt>
                    </PostCardMeta>
                  </LargeCard>
                </Link>
              </div>
            </div>
          </WithScrollToTopButton>
        </main>
        {children}
        <Footer />
      </Wrapper>
    </SEOContent>
  );
};

export default IndexPage;
